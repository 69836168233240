<template>
	<div class="exchange-wrap">
		<Header2022 :navbg='true'></Header2022>
		<div class="content">
			<div class="warp">
				<div class="banner">
					<img src="../../assets/images/yuyue-banner.jpg">
				</div>
				<div class="coupon-list-wrap">
					<div class="left">
						<el-tabs v-model="activeName" @tab-click="handleClick">
							<el-tab-pane label="预约订单" name="order"></el-tab-pane>
							<el-tab-pane label="我的意向	" name="intention"></el-tab-pane>
						</el-tabs>
						<div class="coupon-list" v-if="historyList.length>0">
							<div class="reward-item" v-for="(item,index) in historyList" :key="index">
								<div class="image">
									<img :src="item.order_products[0].image" />
								</div>
								<div class="name-wrap">
									<div class="name">
										<div>{{item.order_products[0].category_name}} | {{item.order_products[0].name}}
										</div>
									</div>
									<div class="time">预估价:￥{{item.order_products[0].total}}</div>
								</div>
								<div class="number">
									<div class="status-wrap" :class="item.order_status_code">
										{{item.order_status_name}}
									</div>
								</div>
							</div>
						</div>
						<div class="coupon-list" v-if="collectionList.length>0">
							<div class="reward-item" v-for="(item,index) in collectionList" :key="index">
								<div class="image">
									<img :src="item.image" />
								</div>
								<div class="name-wrap">
									<div class="name">
										<i>{{item.zone_name}}</i>
										<i v-if="item.city_name">-{{item.city_name}}</i>
										<i v-if="item.county_name">-{{item.county_name}}</i>
									</div>
									<div class="time">
										<i>{{item.name}}</i>
										<i>-{{item.telephone}}</i>	
										<i v-if="item.email">-{{item.email}}</i>
									</div>
									<div class="time">{{item.added_time}}</div>
								</div>
								<div class="number">
									<div class="status-wrap" :class="'status'+item.is_project" @click="reserveTap(item)">
										{{item.botton_name}}
									</div>
								</div>
							</div>
						</div>
						<div class="coupon-list not" v-if="total_exchange<1">
							<div class="tip">暂无预约订单</div>
						</div>
						<el-pagination layout="prev, pager, next" :total="total_exchange" :page-size="per_page"
							:current-page="page" @current-change="changePage" :hide-on-single-page="true"
							class="pagination" v-if="total_exchange>0">
						</el-pagination>
					</div>
					<div class="right">
						<div class="ad-wrap">
							<div class="title">为您推荐</div>
							<div class="img-wrap" @click="adTap">
								<img src="../../assets/images/smart0/ad.jpg">
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
		<Footer2022></Footer2022>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex'
	import Header2022 from '@/components/header2022.vue'
	import Footer2022 from '@/components/footer2022.vue'

	export default {
		data() {
			return {
				activeName: '',
				historyList: [],
				collectionList: [],
				page: 1,
				per_page: 5,
				total_exchange: 0,
			}
		},
		components: {
			Header2022,
			Footer2022,
		},
		computed: {
			...mapState(['user_info', 'houseApi', 'ocApi']),
		},
		watch: {
			activeName: function(e) {
				if(e){
					this.dealData();
				}
			},
			'$route': {
				handler: function(to) {
					if (to.name == "appointment" && to.params.tagName) {
						this.activeName=to.params.tagName;
					}
				},
				immediate: true
			},
		},
		beforeRouteEnter(to, from, next) {
			next(vm => {
				if (from.name == 'weixinPay') {
					history.pushState(null, null, document.URL);
					window.addEventListener('popstate', function() {
						history.pushState(null, null, document.URL);
					});
				}
			})
		},
		methods: {
			adTap(){
				this.$router.push({
					name: 'smart0',
				});
			},
			reserveTap(e){
				if(e.is_project>0){
					this.$router.push({
						name: 'projectList',
						params: {
							id:e.area_Id,
						}
					});
				}
			},
			handleClick(tab, event) {
				this.page=1;
			},
			dealData() {
				if (this.activeName == 'order') {
					this.getOrdersList();
					this.collectionList = [];
				} else {
					this.getCollectionList();
					this.historyList = [];
				}
			},
			getOrdersList: function() {
				let page = this.page;
				let per_page = this.per_page;
				this.$axios.get(this.houseApi + 'pc/house/orders', {
						params: {
							telephone: this.user_info.telephone,
							per_page,
							page
						}
					})
					.then(res => {
						this.historyList = res.data.data
						this.total_exchange = res.data.total
					})
			},
			getCollectionList: function() {
				let page = this.page;
				let per_page = this.per_page;
				this.$axios.get(this.ocApi + 'intentionCollection/list', {
						params: {
							per_page,
							page
						}
					})
					.then(res => {
						this.collectionList = res.data.data.data;
						this.total_exchange = res.data.data.total;
					})
			},
			changePage(page) {
				this.page = page;
				this.dealData();
			}
		},
	}
</script>

<style lang="scss" scoped>
	.exchange-wrap {
		.el-dropdown-link {
			cursor: pointer;
		}

		.pagination {
			text-align: center;
			padding: 20px 0;

			&.el-pagination {
				:deep(button:disabled) {
					background-color: #f7f7f7;
				}

				:deep(.btn-next),
				:deep(.btn-prev) {
					background-color: #f7f7f7;
				}

				:deep(.el-pager) li {
					background-color: #f7f7f7;
				}
			}
		}

		.content {
			background-color: #f7f7f7;
			min-height: calc(100vh - 226px);

			.warp {
				width: 1200px;
				margin: auto;
				padding-top: 114px;

				.banner {
					width: 100%;

					img {
						width: 100%;
					}
				}

				.coupon-list-wrap {
					display: flex;
					justify-content: space-between;
					margin-top: 60px;
					padding-bottom: 60px;

					.left {
						flex: 1;

						.tab-list {
							display: flex;
							justify-content: space-between;
							font-size: 16px;
							padding: 4px 0;

							.title {
								color: #434343;
							}

							.integral-wrap {
								color: #999999;

								.integral {
									color: #00d596;
									font-weight: 600;
								}
							}
						}

						.coupon-list {
							&.not {
								text-align: center;

								.tip {
									padding: 60px 0;
								}
							}

							.reward-item {
								display: flex;
								padding: 10px 48px 10px 19px;
								background-color: #fff;
								border-radius: 4px;
								margin-top: 16px;
								align-items: center;

								.image {
									width: 260px;

									>img {
										width: 100%;
									}
								}

								.name-wrap {
									margin-left: 34px;
									flex: 1;

									.name {
										font-size: 16px;
										color: #434343;
										display: flex;
									}

									.time {
										margin-top: 16px;
										font-size: 12px;
										color: #999999;
									}
								}

								.number {
									.status-wrap {
										font-size: 14px;
										color: #fff;
										border-radius: 100px;
										margin-left: 7px;
										width: 80px;
										height: 30px;
										line-height: 30px;
										text-align: center;

										&.paid {
											background: linear-gradient(90deg,
													rgba(15, 233, 145, 1),
													rgba(1, 204, 244, 1));
										}

										&.unpaid {
											background: linear-gradient(90deg,
													rgba(248, 87, 166, 1),
													rgba(255, 88, 88, 1));
										}

										&.cancelled {
											background: linear-gradient(90deg,
													rgba(96, 108, 136, 1),
													rgba(63, 76, 107, 1));
										}

										&.refunded {
											background: linear-gradient(90deg,
													rgba(255, 128, 8, 1),
													rgba(255, 200, 55, 1));
										}

										&.processing {
											background: linear-gradient(90deg,
													rgba(43, 133, 228, 1),
													rgba(43, 120, 190, 1));
										}
										&.status1{
											background: linear-gradient(90deg,
													rgba(15, 233, 145, 1),
													rgba(1, 204, 244, 1));
										}
										&.status0{
											background:#909399;
										}
									}
								}
							}
						}
					}

					.right {
						width: 320px;
						margin-left: 40px;

						.ad-wrap {
							width: 100%;
							border-radius: 4px;
							padding: 16px 20px 20px 20px;
							box-sizing: border-box;
							background-color: #fff;
							margin-top: 46px;

							.title {
								font-size: 16px;
								color: #000000;
								font-weight: 400;
							}

							.img-wrap {
								width: 100%;
								margin-top: 14px;

								>img {
									width: 100%;
								}
							}
						}
					}
				}
			}
		}
	}
</style>